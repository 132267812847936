import { http } from '@/http/axios.js'
// 列表
export function getList(params) {
  return http({
    url: '/company/user/list',
    method: 'get',
    params
  })
}
// 详情
export function detail(id) {
  return http({
    url: '/company/user/detail',
    method: 'get',
    params: {id : id}
  })
}

export function editUser(data) {
  return http({
    url: '/company/user/edit',
    method: 'post',
    data
  })
}

export function getAllMember(params) {
  return http({
    url: '/company/member/getAll',
    method: 'get',
    params
  })
}



export function getDownloadUserAPI(params) {
  return http({
    url: '/company/user/member/list',
    method: 'get',
    params
  })
}


export function getDownloadUserDetailAPI(id) {
  return http({
    url: '/company/download/member/detail',
    method: 'get',
    params: {id}
  })
}


export function getDownloadLogAPI(params) {
  return http({
    url: '/company/download/log/list',
    method: 'get',
    params
  })
}




export function getDownloadOrderListAPI(params) {
  return http({
    url: '/company/order/list',
    method: 'get',
    params
  })
}
